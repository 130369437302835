<template>
  <!-- 联系人列表 -->
  <div
    id="xppContactList"
    v-if="dialogElementShow.xppContactListShow"
    :style="xppContactDataInfo"
  >
    <div id="xppContactListHead">
      <p>团队成员</p>
      <p>
        <span class="msg-group"> 移动 : <b>tab</b> 或 <b>↑</b> <b>↓</b> </span>
        <span class="msg-group"> 选择：<b>↵</b> </span>
        <span class="msg-group"> 取消：<b>esc</b> </span>
      </p>
    </div>
    <ul
      id="xppContactListBody"
      class="xpp-scrollbar"
      ref="xppContactListBody"
      @click="selectContactSubmit"
      @mouseover="itemHover"
      v-loading="viewload"
      v-if="xppTeamMemberList.length"
    >
      <template v-for="(item, index) in contactsFilterList">
        <li
          class="xpp-contact-item"
          v-if="item.status === 1"
          :key="item.id"
          :data-id="item.userId"
          :data-idx="index"
          :class="{ 'active-h': index === memberHoverActive }"
        >
          <span
            class="xpp-contact-photo xpp-user-photo"
            :style="item.headImg | styleUserPhoto"
          ></span>
          <span class="xpp-contact-name">{{ item.nickName }}</span>
          <span class="xpp-contact-email">{{ item.email }}</span>
        </li>
      </template>
    </ul>
    <div class="contact-error" v-else>
      {{ XppContactListError.msg }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "XppContactList",
  computed: {
    contactsFilterList() {
      let targetText = this.xppContactsFilterInfo.text.trim();
      let text = new RegExp(targetText, "i");
      let arr = this.xppTeamMemberList.filter(item => {
        return text.test(item.nickName) || text.test(item.email);
      });
      return targetText ? arr : this.xppTeamMemberList;
    },
    ...mapGetters([
      "dialogElementShow",
      "xppContactDataInfo",
      "xppTeamMemberList",
      "xppContactNoticeInfo",
      "xppContactsFilterInfo",
      "xppQuillEditContainer",
      "XppContactListError"
    ])
  },
  watch: {
    "dialogElementShow.xppContactListShow"(val) {
      let quill = this.xppQuillEditContainer;
      if (val === true) {
        this.viewshow();
        this.quillEventBind(quill);
      } else {
        this.quillEventRemove(quill);
        this.$RemoveXppContactsFilterInfo();
      }
    },
    contactsFilterList() {
      this.memberHoverActive = 0;
    },
    memberHoverActive(n_val, o_val) {
      let d_value = n_val - o_val; // 变化差值
      if (Math.abs(d_value) > 1) return; // 差值大于1 为hover操作，不进行辅助滚动操作
      let direction = d_value >= 0; // true 为向下
      let parentNode = this.$refs.xppContactListBody; // 容器盒子
      let old_node = document.getElementsByClassName("active-h")[0]; // 当前选中项目
      let old_offsetTop =
        old_node.offsetTop + old_node.clientHeight + (direction ? 30 : -30);
      let scrollTop = null;
      let offsetY =
        parentNode.scrollTop + parentNode.clientHeight - old_offsetTop;
      // 下一个目标被隐藏
      if (offsetY <= 0) {
        scrollTop = parentNode.scrollTop + 30;
      } else if (offsetY >= 210) {
        scrollTop = parentNode.scrollTop - 30;
      }
      scrollTop !== null && parentNode.scrollTo(0, scrollTop);
    }
  },
  data() {
    return {
      viewload: false,
      firstUp: false,
      memberHoverActive: 0,
      activeEventTarget: "keydown",
      oldEditContentLength: 0,
      searchText: "" // @后手动输入的内容
    };
  },
  methods: {
    // 视图显示后调用
    async viewshow() {
      if (this.firstUp) return;
      this.viewload = true;
      await this.$UpdataTeamMemberList();
      this.viewload = false;
      this.firstUp = true;
    },
    // 选择 @ 联系人
    selectContactSubmit({ target }) {
      let id = target.dataset.id;
      for (let item of this.contactsFilterList) {
        if (item.userId === id) {
          setTimeout(() => {
            this.$DiaLoginFoUpDate({ key: "xppContactListShow", value: false });
          }, 40);
          let name = `@${item.nickName}`;
          if (this.xppQuillEditContainer) {
            let quill = this.xppQuillEditContainer;
            quill.deleteText(
              this.oldEditContentLength - 1,
              quill.getSelection().index
            );
            quill.insertEmbed(quill.getSelection().index, "mention", {
              id,
              name: item.nickName,
              mid: quill.editMentionId
            });
            quill.setSelection(quill.getSelection().index + 1, 0);
          } else {
            let n_range = window.getSelection().getRangeAt(0);
            let o_range = this.xppContactsFilterInfo.selection;
            n_range.setStart(o_range.startContainer, o_range.endOffset - 1);
            n_range.setEnd(n_range.endContainer, n_range.endOffset);
            document.execCommand(
              "insertHTML",
              false,
              `<span data-key="${id}">&nbsp;<span class="remind-team-members-tag" contenteditable="false">${name}</span>&nbsp;</span>&nbsp;`
            );
          }
          let data = {};
          data[id] = true;
          this.$UpdataVuexState({
            key: "xppContactNoticeInfo",
            data
          });
          console.log(this.xppContactNoticeInfo);
          break;
        }
      }
    },
    // hover 联系人
    itemHover({ target }) {
      let idx = target.dataset.idx;
      if (this.activeEventTarget !== "mousemove") {
        this.activeEventTarget = "mousemove";
        return;
      }
      if (!idx) return;
      this.memberHoverActive = idx - 0;
    },
    quillEventBind(quill) {
      document.addEventListener("keydown", this.quillKeyDown);
      if (!quill) return;
      this.oldEditContentLength = quill.getLength() - 1;
    },
    quillEventRemove() {
      document.removeEventListener("keydown", this.quillKeyDown);
    },
    quillKeyDown(event) {
      switch (event.keyCode) {
        case 37:
        case 38: // 上一个
          event.preventDefault();
          event.stopPropagation();
          this.activeEventTarget = "keydown";
          this.memberHoverActive = Math.max(0, this.memberHoverActive - 1);
          break;
        case 9:
          event.preventDefault();
          event.stopPropagation();
          this.memberHoverActive = Math.min(
            this.contactsFilterList.length - 1,
            this.memberHoverActive + 1
          );
          break;
        case 39:
        case 40: // 下一个
          this.activeEventTarget = "keydown";
          this.memberHoverActive = Math.min(
            this.contactsFilterList.length - 1,
            this.memberHoverActive + 1
          );
          break;
        case 13: // 回车
          event.preventDefault();
          event.stopPropagation();
          let quill = this.xppQuillEditContainer;
          if (quill) {
            console.log(quill.getSelection());
            quill.deleteText(quill.getSelection().index - 1, 1);
          }
          this.selectContactSubmit({
            target: {
              dataset: {
                id: this.contactsFilterList[this.memberHoverActive].userId
              }
            }
          });
          break;
      }
    },
    ...mapMutations([
      "$DiaLoginFoUpDate",
      "$UpdataVuexState",
      "$RemoveXppContactsFilterInfo"
    ]),
    ...mapActions(["$UpdataTeamMemberList"])
  }
};
</script>

<style lang="scss" scoped>
#xppContactList {
  width: 100%;
  max-width: 308px;
  z-index: 1001;
  position: absolute;
  bottom: 100%;
  left: 0;
  height: auto;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  .contact-error {
    padding: 15px;
    font-size: 12px;
    text-align: center;
    color: #7f7f83;
  }
}

#xppContactListHead {
  padding: 5px 10px 4px;
  background: #faf8f6;
  color: #7f7f83;
  font-size: 12px;
  line-height: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#xppContactListBody {
  position: relative;
  max-height: 248px;
  min-height: 30px;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .xpp-contact-item {
    cursor: pointer;
    line-height: 30px;
    padding: 0 10px;
    font-weight: 700;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    span {
      flex: none;
      font-size: 13px;
      pointer-events: none;
    }
    &.active-h {
      background-color: #f5a623;
      span {
        color: #fff;
      }
    }
  }
  .xpp-contact-photo {
    width: 20px;
    height: 20px;
    background-color: #999999;
    margin-right: 6px;
  }
  .xpp-contact-name {
    max-width: calc(100% - 40% - 32px);
    padding-right: 6px;
    font-weight: 600;
    color: #373839;
  }
  .xpp-contact-name,
  .xpp-contact-email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .xpp-contact-email {
    max-width: calc(100% - 60% - 26px);
    color: #717274;
    font-weight: 300;
    opacity: 0.6;
  }
}
</style>
